import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Inject, makeEnvironmentProviders } from '@angular/core';
import { DEVICE_BASE_KIND } from '@bcf-v2-utilities/device-info/token';
import { updateWorkerToolkitFromUi } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/providers';
import { registerWorkerInstance } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/tokens';
import { BrowserSideMessage, initWorkerMessage } from '@bcf-vanilla-ts-v1-platforms/platform-worker/common/messages';
import { updateWorkerToolkitData } from '@bcf-vanilla-ts-v1-platforms/platform-worker/worker/bootstrap-worker';
import {
  WorkerToolkit,
  provideWorkerToolkit
} from '@bcf-vanilla-ts-v1-platforms/platform-worker/worker/worker-toolkit';
import { DeviceBaseType } from '@bcf-vanilla-ts-v1-utilities/device-info/types';
import { BrowserSideBlocController } from './browser-side-bloc-controller';
import { WorkerWrapper } from './worker-wrapper';
import { WorkerWrapperVirtual } from './worker-wrapper-virtual';

export function provideWorker(worker: Worker, appRuntimeId: number): EnvironmentProviders {
  registerWorkerInstance(worker);
  worker.postMessage(initWorkerMessage(appRuntimeId));
  return makeEnvironmentProviders([
    {
      provide: WorkerWrapper,
      useFactory: () => new WorkerWrapper(worker)
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (document: Document, deviceKindBase: DeviceBaseType) => () => {
        updateWorkerToolkitFromUi(worker, document, deviceKindBase);
      },
      deps: [[new Inject(DOCUMENT)], [new Inject(DEVICE_BASE_KIND)]],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (browserSideBlocController: BrowserSideBlocController<any>) => () => {
        browserSideBlocController.init();
      },
      deps: [BrowserSideBlocController],
      multi: true
    }
  ]);
}

export function provideWorkerVirtual(appRuntimeId: number): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: WorkerWrapper,
      useClass: WorkerWrapperVirtual
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (workerWrapper: WorkerWrapper, document: Document, deviceKindBase: DeviceBaseType) => () => {
        const toolkit: WorkerToolkit = provideWorkerToolkit();
        workerWrapper.getMessage<BrowserSideMessage>().subscribe((message: BrowserSideMessage) => {
          updateWorkerToolkitData(message, toolkit);
        });
        workerWrapper.postMessage(initWorkerMessage(appRuntimeId));
        updateWorkerToolkitFromUi(workerWrapper, document, deviceKindBase);
      },
      deps: [WorkerWrapper, [new Inject(DOCUMENT)], [new Inject(DEVICE_BASE_KIND)]],
      multi: true
    }
  ]);
}
