import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { from, mergeMap, Observable, OperatorFunction, switchMap, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { HttpOptions } from './types';

export type HttpError = {
  readonly _type: '_HttpError';
  readonly url: string;
  readonly status: number;
  readonly statusText: string;
  readonly error: Record<string, any>;
  readonly responseHeaders: Record<string, string>;
};

async function createHttpError(response: Response): Promise<HttpError> {
  const responseHeaders: Record<string, string> = {};

  response.headers.forEach((value: string, key: string) => {
    responseHeaders[key] = value;
  });

  return {
    _type: '_HttpError',
    url: response.url,
    status: response.status,
    statusText: response.statusText,
    error: await response.json(),
    responseHeaders: responseHeaders
  };
}

export function isHttpError(error: any): error is HttpError {
  return error && error._type === '_HttpError';
}

export class HttpClient {
  public get<T extends object>(url: string, options?: HttpOptions): Observable<T> {
    return fromFetch(url, {
      ...options,
      method: 'GET',
      headers: {
        ...(options?.headers ?? {})
      }
    }).pipe(this._handleResponse<T>());
  }

  public post<T>(url: string, body: FormData, options?: HttpOptions): Observable<T>;
  public post<T>(url: string, body: object, options?: HttpOptions): Observable<T>;
  public post<T>(url: string, body: object | FormData, options?: HttpOptions): Observable<T> {
    if (body instanceof FormData) {
      return fromFetch(url, {
        ...options,
        method: 'POST',
        body: body,
        headers: {
          ...(options?.headers ?? {})
        }
      }).pipe(this._handleResponse<T>());
    }

    return fromFetch(url, {
      ...options,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.headers ?? {})
      },
      body: JSON.stringify(body)
    }).pipe(this._handleResponse<T>());
  }

  public options<T>(url: string, body: object | undefined = undefined, options?: HttpOptions): Observable<T> {
    return fromFetch(url, {
      ...options,
      method: 'Options',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.headers ?? {})
      },
      body: body ? JSON.stringify(body) : undefined
    }).pipe(this._handleResponse<T>());
  }

  private _handleResponse<T>(): OperatorFunction<Response, T> {
    return switchMap((response: Response) => {
      if (response.ok) {
        return response.json();
      } else {
        return from(createHttpError(response)).pipe(
          mergeMap((error: HttpError) => {
            return throwError(() => error);
          })
        );
      }
    });
  }
}

export function provideHttpClient(): HttpClient {
  return provideSingleton(HttpClient, () => new HttpClient());
}
